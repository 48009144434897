import PropTypes from "prop-types"
import React from "react"
import TimeAgo from "timeago-react"

const Footer = ({ posted, updated }) => (
  <footer className="footer">
  { posted && <p>Posted: <TimeAgo datetime={posted} locale="en" /></p>}
  { updated && <p>Updated: <TimeAgo datetime={updated} locale="en" /></p>}
  </footer>
)

export default Footer
