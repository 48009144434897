import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/nighttime-vector.svg"
import MenuBar from "gatsby-theme-wirehead-bulma/src/menubar"

const Header = ({ siteTitle }) => (
  <div className="headerbar">
    <div className="headerlogo">
    </div>
    <MenuBar siteTitle={siteTitle} endLinks={
      <Link to="/email" className="navbar-item">
          &#9993; Mail
        </Link>
    }/>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
